import React from 'react'
import tw from 'twin.macro'
import {
  faBuilding,
  faChevronRight,
  faClipboard,
  faHome,
  faMapMarkerAlt,
  faMobileAlt,
} from '@fortawesome/free-solid-svg-icons'
import { ButtonLink } from './Button'
import Cookies from 'js-cookie'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { navigate } from 'gatsby'
import { formatPhoneNumber } from '../helpers'
import A from '../components/A'
import PropTypes from 'prop-types'

const BranchCard = ({
  data,
  showButton,
  hideQuoteButton,
  ...remainingProps
}) => {
  const types = Array.isArray(data.types) ? data.types : data.types.split(',')

  const commercialOnly =
    types.includes('commercial') && !types.includes('residential')

  const setBranch = () => {
    // Cookies.set('localResidentialBranchData', data)
    Cookies.set('zipCode', data.branchMeta.branchOfficeZip)
  }

  return (
    <div tw="p-4 rounded-lg" {...remainingProps}>
      <div tw="flex flex-row items-center justify-between mb-4">
        <span tw="text-xl font-bold text-left">
          <FontAwesomeIcon icon={faMapMarkerAlt} tw="mr-0 text-yellow-600" />{' '}
          {data.title}
        </span>
        <div tw="text-gray-500 flex flex-row">
          {/* Residential Icon */}
          <FontAwesomeIcon
            icon={faHome}
            css={[
              tw`mr-2`,
              types.includes('residential') && tw`text-yellow-600`,
            ]}
          />
          {/* Commercial Icon */}
          <FontAwesomeIcon
            icon={faBuilding}
            css={[types.includes('commercial') && tw`text-yellow-600`]}
          />
        </div>
      </div>
      <div
        css={[
          tw`text-gray-800 mb-5 text-sm grid grid-cols-2 col-gap-4 md:(grid-cols-1 mb-5) xl:(grid-cols-2 mb-0)`,
          // `@media (max-width: 447.98px) {
          //    & {
          //      display: flex;
          //    }
          //   }`,
        ]}
      >
        <div tw="mb-2">
          <div tw="mb-2">
            <p>
              <strong>Branch Office</strong>
            </p>
            <address tw="not-italic">
              {data.branchMeta.branchOfficeAddress || data.officeAddress}
              <br />
              {data.branchMeta.branchOfficeCity},{' '}
              {data.branchMeta.branchOfficeState}{' '}
              {data.branchMeta.branchOfficeZip}
            </address>
          </div>
          <p>
            <FontAwesomeIcon icon={faMobileAlt} tw="text-gray-500" />{' '}
            <A href={`tel:${data.branchMeta.branchOfficePhone}`}>
              {formatPhoneNumber(data.branchMeta.branchOfficePhone)}
            </A>
          </p>
        </div>
        <div>
          <p tw="mb-2">
            <strong>Office Hours</strong>
            <br />
            {data.branchMeta.branchOfficeHours}
          </p>
          <p>
            {showButton && (
              <>
                <FontAwesomeIcon icon={faClipboard} tw="text-gray-500" />{' '}
                <A
                  href={
                    commercialOnly
                      ? '/commercial/quote/'
                      : '/residential/quote/'
                  }
                  onClick={e => {
                    e.preventDefault()
                    setBranch()
                    navigate(
                      commercialOnly
                        ? '/commercial/quote/'
                        : '/residential/quote/'
                    )
                  }}
                >
                  {commercialOnly ? 'Request a Bid' : 'Request a Quote'}
                </A>
              </>
            )}
          </p>
        </div>
      </div>
      {showButton && (
        <ButtonLink href={`/branches/${data.slug}`} large>
          View Details <FontAwesomeIcon icon={faChevronRight} tw="ml-4" />
        </ButtonLink>
      )}
    </div>
  )
}

BranchCard.propTypes = {
  showButton: PropTypes.bool,
}

BranchCard.defaultProps = {
  showButton: false,
}

export default BranchCard
